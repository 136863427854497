.zonesTab {
  background-color: #fff;
  margin: 10px 0px;
}

.zonesTab ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.zonesTab ul > li:first-child {
  width: 50%;
  margin-right: 10px;
  position: relative;
}

.zonesTab ul > li.poiBtn svg {
  font-size: 16px;
}

.zonesTab ul > li.poiBtn > button {
  padding: 6px;
}

.zonesTab .iconButton.searchBtn {
  padding: 4px;
  top: 0;
  right: -2px;
}

.zonesTab li.poiBtn {
  margin-right: 5px;
}

.dialogBody.zonePopupBody {
  min-height: 250px;
  min-width: 400px;
}

.zonePopupWrapper {
  left: 80px !important;
  top: 110px !important;
}

.switch {
  margin-top: 0px;
  padding-left: 5px;
}

.switch span {
  font-size: 12px;
  font-weight: 500;
}

.switchRoot {
  padding-right: 5px;
}

input#zoneColor {
  background: #eb0e60;
  color: #fff !important;
  font-weight: bold;
  border-radius: 5px;
  border: none;
}

.zonesTab {
  background-color: #fff;
  margin: 10px 0px;
}

.zonesTab ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.zonesTab ul > li:first-child {
  width: 50%;
  margin-right: 10px;
  position: relative;
}

.zonesTab ul > li.poiBtn svg {
  font-size: 16px;
}

.zonesTab ul > li.poiBtn > button {
  padding: 6px;
}

.zonesTab ul > li .SearchInput {
  margin-top: 3px !important;
  font-size: 12px !important;
  min-height: 15px !important;
}

.zonesTab .iconButton.searchBtn {
  padding: 4px;
  top: 0;
  right: -2px;
}

.zonesTab li.poiBtn {
  margin-right: 5px;
}

.smallIcon {
  padding: 5px !important;
}

.smallIcon svg {
  font-size: 14px;
}

div#zoneColorPicker > div:nth-child(2) {
  margin-right: 0 !important;
}
div#zoneColorPicker > div:nth-child(2) > div {
  right: 15px;
  top: 40px;
}
