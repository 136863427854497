div#dashboardDialog {
  width: 100%;
}
.fleetGrid {
  width: 98%;
}
.fleetGrid tbody.MuiTableBody-root > tr > td {
  border-right: 1px solid #ccc;
}
.fleetGrid tr.MuiTableRow-head > th {
  border-right: 1px solid #ccc;
}
