div#reportTopBar {
  display: flex;
  background: white;
  box-shadow: 0px 2px 5px #3535493b;
  height: 50px;
  align-items: center;
  padding: 0px 15px;
  position: fixed;
  width: 100%;
  z-index: 9;
  justify-content: space-between;
}

.reports {
  height: 100%;
}

.report-leftbar {
  position: fixed;
  z-index: 99999999;
  top: 0;
}

.reports .leftFixedMenu > ul.topmenu {
  margin-top: 60px;
}

.mapTabTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
}

.mapTabTitle small {
  font-size: 12px;
}

.reportSideBarWrapper {
  padding: 0px 8px;
  height: 100%;
  overflow-y: auto;
}

nav.menu > div {
  padding-left: 0;
}

.reportPageWrapper {
  margin-left: 60px;
  padding: 5px 15px;
  padding-top: 50px;
  background: var(--reportBgColor);
}

table#reportGrid {
  background: #fff;
}

.charts {
  display: flex;
  flex-wrap: wrap;
}

.backdrop {
  z-index: 9999999999 !important;
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.card {
  width: 18%;
  padding: 0px 2px;
  margin-bottom: 30px;
  padding-right: 20px;
}

.reports-card > div {
  padding: 10px !important;
  display: flex;
  flex-direction: column;
}

.reportcard-header {
  display: flex;
  align-items: center;
  padding: 10px;
}

p.viewmore-link {
  color: var(--primaryColor);
  cursor: pointer;
  text-align: center;
  margin: 10px 0px;
  font-size: 14px;
  margin-bottom: 0;
}

.reportcard-icon {
  width: 30%;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 1px 2px 5px #c6c6c6;
}

.reportcard-icon.total {
  background: var(--primaryColor);
}

.reportPopupWrapper.all h2 {
  color: var(--primaryColor);
}

.reportcard-icon.running {
  background: green;
}

.reportcard-icon.idle {
  background: darkorange;
}

.reportcard-icon.stopped {
  background: red;
}

.reportcard-icon.nosignal {
  background: #c6c6c6;
}

.reportcard-icon.available {
  background: darkviolet;
}

.reportcard-icon.busy {
  background: red;
}

div#reportFooter {
  text-align: center;
  font-size: 12px;
  padding: 5px 0px;
  background: #ededed;
}

div#reportFooter p {
  margin: 4px 0px;
  margin-top: 6px;
}

.reportcard-icon svg {
  color: #fff;
}

.reportcard-details {
  width: 70%;
  padding: 0px 5px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.reportcard-title {
  margin-top: 10px;
  margin-bottom: 0;
  color: gray;
  font-size: 14px;
  font-weight: normal;
}

.reportcard-desc {
  font-size: 30px;
  margin-top: 8px;
  margin-bottom: 5px;
}

nav.breadcrumb {
  padding-left: 0px;
}

.whiteLoader svg {
  color: #fff;
}

.reportPopup-wrapper {
  display: flex;
  justify-content: center;
  min-height: 300px;
  align-items: center;
  flex-direction: column;
}

p.reportDescription {
  margin-bottom: 20px;
  margin-top: -10px;
  font-size: 12px;
  color: #0000008a;
}

.reportFilters {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: center;
}
.reportFilters button.primaryBtn {
  min-width: 130px;
  height: 38px;
}

.width10 {
  width: 10%;
}

.width15 {
  width: 15%;
}

.width20 {
  width: 20%;
}

.width25 {
  width: 25%;
}

.width30 {
  width: 30%;
}

.width33 {
  width: 33%;
}

.width50 {
  width: 50%;
}

.width100 {
  width: 100%;
}

.height100 {
  height: 100%;
}

.padLeft-10 {
  padding-left: 10px;
}

.primaryBtn {
  background: var(--primaryColor) !important;
  color: #fff !important;
  padding: 4px 15px !important;
  font-size: 12px !important;
  font-weight: bold !important;
}

.primaryBtn:hover {
  background: var(--primaryColor) !important;
}

.reportPageWrapper .uidatepicker label {
  display: none;
}

.reportPageWrapper .uidatepicker {
  padding: 0px 15px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

.datepickerTop {
  display: flex;
  margin-bottom: 8px;
}
.datepickerBottom ul {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  margin-top: -4px;
}
.datepickerBottom ul > li {
  color: #6b6ade;
  padding-right: 8px;
  cursor: pointer;
}
.datepickerBottom ul > li:first-child {
  cursor: default;
  font-weight: 500;
  color: #353549;
}
.reportFilters .uidatepicker .MuiInput-root {
  margin-top: 0;
}

.dataTables_scrollHead th {
  font-size: 12px;
  color: #fff;
  text-align: left;
}

.dataTables_scrollBody {
  font-size: 12px;
}

.autoCompleteDropdown input {
  font-size: 12px;
  font-weight: 500;
  top: 3px;
  position: relative;
}

label.MuiInputLabel-root[data-shrink="false"] {
  font-size: 12px;
  font-weight: 500;
}

.autocomplete-box > div > div {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.autocompletePopper {
  border: 1px solid #c6c6c6;
  border-top: none;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

div#reportGrid_info {
  font-size: 12px;
}

.reportSideBarWrapper .MuiListItemText-root {
  padding-left: 0px;
}

.reportSideBarWrapper .menuicon {
  justify-content: center;
  min-width: 40px;
}

.subtitle {
  font-size: 16px;
  margin: 5px 0px;
  color: var(--primaryColor);
  font-weight: 500;
}

.reportSideBarWrapper > nav > div.MuiListSubheader-root {
  color: var(--primaryColor);
  font-size: 16px;
  font-weight: 500;
}

.report-leftbar .leftdrawer-menu {
  width: 300px;
  box-shadow: 2px 0px 50px #5f5f5f;
}

.menuicon svg {
  font-size: 20px;
  color: var(--primaryColor);
}

.report-menu {
  padding: 4px 0px !important;
  margin-bottom: 5px !important;
}

.report-menu.active span {
  font-weight: 500;
}

.report-menu span {
  font-size: 14px;
  color: #000;
  padding-top: 2px;
}

.leftdrawer-menu {
  box-shadow: 0px 1px 10px #c4c4c4;
}

.dialogBox.reportPopupWrapper {
  width: 100%;
}

.reportPopup-wrapper > div {
  width: 100%;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.primaryLoader {
  color: var(--primaryColor) !important;
}

table.dataTable.no-footer {
  margin-top: 0px !important;
  padding: 4px 0px;
}

.dataTables_scrollHead th:before,
.dataTables_scrollHead th:after {
  top: 0px;
}

table#reportGrid tbody td {
  border: 1px solid #a9a9a9;
  border-top: 0;
  border-right: 0;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
  margin-bottom: 5px;
  margin-right: 5px;
  font-size: 14px;
}

.reportPopupWrapper .dialogContent {
  padding-top: 0px !important;
}

.uidatepicker button.MuiButtonBase-root {
  padding: 8px;
}

.dt-buttons {
  margin-bottom: -20px;
}

div#topLeftBox {
  padding-left: 60px;
}

div#topRightBox {
  padding-right: 25px;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 15px;
  padding-top: 4px;
}

div#topRightBox > button {
  padding: 8px;
}

div#topRightBox > button svg {
  color: #404040;
  font-size: 18px;
}

div#reportUserInfo h4 {
  margin-bottom: 0;
  margin-top: -2px;
  font-size: 14px;
  color: #676767;
  font-weight: 500;
}

div#reportUserInfo p {
  margin-bottom: 0;
  margin-top: 0px;
  font-size: 12px;
  color: #a5a5a5;
  text-transform: capitalize;
}

div#reportLogo img {
  border-radius: 2px;
}

div#reportUserInfo {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
}

div#reportGrid_paginate ul {
  display: flex;
  list-style: none;
  margin-top: -15px;
}

.reportGridWrapper {
  overflow: auto;
}

div#reportGrid_paginate ul > li {
  padding: 0px 5px;
  font-size: 12px;
}

div#reportGrid_paginate ul > li a {
  text-decoration: none;
}

div#reportGrid_paginate ul > li.disabled a {
  color: #9d9d9d !important;
}

table#reportGrid.paginated {
  font-size: 12px;
}

table#reportGrid.paginated thead th {
  border: 1px solid #c0c0c0;
  border-right: none;
  background: #2c2c45bd;
  color: #fff;
}

table#reportGrid.paginated thead th:last-child {
  border-right: 1px solid #c0c0c0;
}

table#reportGrid tbody td:last-child {
  border-right: 1px solid #c0c0c0;
}

div#reportGrid_paginate ul > li.active {
  font-weight: 500;
}

table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after {
  top: 2px;
}

.dataTables_scrollHead {
  background: #464667;
}

div#reportGrid_wrapper thead th {
  color: #fff;
  white-space: nowrap;
  border-right: 1px solid #6b6b7b;
}

.reportFilters .autocomplete-box input {
  font-size: 12px;
  top: 2px;
  position: relative;
}

.submenu .report-menu {
  padding-left: 5px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.submenu .report-menu > div {
  min-width: auto;
}

.submenu .report-menu > div svg {
  padding-right: 5px;
  font-size: 20px;
}

.submenu {
  margin-left: 38px !important;
}

.dashboardFilterMenu {
  padding: 0 !important;
}

.dashboardFilterMenu > div {
  padding: 0px 10px;
}

.dashboardFilterMenu span {
  font-size: 12px;
}

.analysis .subtitle {
  margin-bottom: 10px;
}

div#dashboardStats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}

div#dashboardStats .statsBox {
  box-shadow: 1px 2px 10px #dbdbdb;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #c6c6c6;
  margin-bottom: 30px;
  padding: 0px 10px;
}

div#dashboardStats .statsBox .statsFilter {
  display: flex;
  justify-content: flex-end;
}

div#dashboardStats .statsBox .statsFilter .dropdown {
  width: 200px;
}

.menuicon svg.svg-inline--fa {
  font-size: 16px;
}

.chartFilter {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 20%;
  right: 15px;
  top: 15px;
  z-index: 999;
}

.chartFilter button {
  background: #fff;
  color: #000;
  margin-bottom: 5px;
  min-width: auto;
  padding: 2px 8px;
  box-shadow: none;
  z-index: 9999;
  margin-left: 5px;
}

.chartFilter button:hover {
  background: #fff;
}

.chartFilter button svg {
  font-size: 18px;
}

.chartFilter p {
  position: absolute;
  z-index: 99;
  left: -1px;
  top: 5px;
  font-size: 12px;
  color: #353549b0;
  margin-bottom: 0;
  font-weight: 500;
  margin-top: 0;
}

.graphMenuPopover nav > div.active span {
  font-weight: 500;
}

#reportPageContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15px;
}

div#reportPageContainer div#reportDashLeft {
  width: 74%;
}
div#reportDashLeft .subtitle {
  margin-top: 15px;
  margin-bottom: 0;
  color: var(--secondaryColor);
  font-size: 14px;
}

div#reportPageContainer #reportDashRight {
  width: 25%;
}

div#reportPageContainer .reportDashCard {
  border: 1px solid #dfdbdb;
  border-radius: 4px;
  padding: 0px 15px;
  margin-bottom: 15px;
  background: #fff;
  position: relative;
}

.reportCardHeader h4 {
  color: #434343;
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 15px;
}

.reportCardHeader span {
  color: #9d9d9d;
  font-size: 12px;
  font-weight: 500;
  padding-left: 5px;
}
.reportCardList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  padding: 5px 0px;
  transition: all 0.1s;
}
.reportCardList > span.reportCardListLeft {
  background: #efeff9;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.reportCardList > span.reportCardListMid {
  width: calc(70% - 25px);
  font-size: 14px;
  color: var(--primaryColor);
  font-weight: 500;
}
.reportCardList > span.reportCardListRight {
  width: 30%;
  font-size: 16px;
  color: var(--secondaryColor);
  font-weight: 500;
  text-align: center;
  position: relative;
  top: 1px;
}
.reportDashSmallGraphs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}
.reportDashSmallGraphs > div {
  width: 28%;
}

span.stopped {
  color: red;
}
span.running {
  color: green;
}

.reportCardList.cursor:hover span {
  font-weight: bold;
  cursor: pointer;
}
.reportCardBody > div.cursor:hover > span.reportCardListLeft {
  background: var(--primaryColor);
}
.formGroup {
  margin-bottom: 15px;
  margin-top: 15px;
}
.formGroup > label {
  font-size: 12px !important;
  font-weight: 500 !important;
}
