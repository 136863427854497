.menulist {
    position: absolute;
    right: 3px;
    padding: 5px;
    margin-top: 8px;
    z-index: 99999;
    box-shadow: 2px 2px 10px #e0e0e0;
    background-color: #fff;
    border-radius: 3px;
    display: none;
    opacity: 0;
    visibility: hidden;
    border: 1px solid #e5e5e5;
    width: 175px;
}

.menulist::before {
    content: '';
    position: absolute;
    right: 10px;
    top: -8px;
    border-style: solid;
    border-width: 0px 8px 8px 8px;
    border-color: #4b1b9a00 #06060600 #ffffff #f1060600;
    -webkit-filter: drop-shadow(0px -3px 2px #0000000e);
    filter: drop-shadow(0px -3px 2px #0000000e)
}

.menulist.active {
    display: block;
    opacity: 1;
    visibility: visible;
    z-index: 99999999999999999;
    position: fixed;
}

.menuListItem {
    padding: 5px !important;
}

.menuListItemText span {
    font-size: 12px;
}

.menuListItemText {
    padding-left: 0px !important;
}

.subMenu>svg {
    font-size: 12px;
}

.subMenuList {
    margin-left: 6px;
}

div#voptions>nav {
    padding: 0;
}