div#liveTrack .leaflet-control-scale.leaflet-control {
    top: 0px;
}

div#liveTrack div#bottomBar button {
    width: 115px;
    padding-left: 10px;
}

div#liveTrack span.mapTypeIcon {
    right: -15px;
    top: 3px;
}

div#liveTrack .leaflet-popup-content {
    width: 260px !important;
}

div#liveTrack #msg {
    text-align: center;
}