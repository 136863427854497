.selectDropdown>div {
    font-size: 12px;
    padding: 2px 20px 2px 4px;
    font-weight: 500;
}

.selectDropdown>svg {
    font-size: 18px;
    padding-top: 2px;
}

.selectDropdown {
    border: 1px solid #DFDBDB !important;
    border-radius: 4px !important;
    min-width: 150px !important;
    padding: 5px 8px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}

.selectFormControl>div::after,
.selectFormControl>div::before {
    display: none;
}