.multiSelectionWrapper {
  border: 1px solid #ccc;
  padding: 0px 8px;
  border-radius: 2px;
  margin-top: 2px;
}
.multiSelectionBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  border-radius: 4px;
  padding: 0px 5px;
  width: 98%;
  margin-bottom: 4px;
}
.multiSelectionOptions {
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  margin: 10px 0px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ccc;
}
.multiSelectionOptions .txtfield {
  margin-bottom: 0px !important;
}
