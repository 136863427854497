.etaTab {
    min-height: auto !important;
}

.etaTab button.etatab {
    padding: 5px !important;
    min-height: auto;
    min-width: 130px;
    font-size: 12px;
    border-radius: 3px;
}

.etaTab button.activeTab {
    background-color: #fff;
    color: #000 !important;
}

.etaTabIndicator {
    background-color: #6B6BDE !important;
}

div#etaPopupWrapper {
    padding: 5px 0px;
}

.etaTabContainer {
    padding-top: 0px;
    box-shadow: none !important;
    margin-top: -5px;
    padding-right: 6px;
}

.etaTab>div>div {
    border-bottom: 1px solid whitesmoke;
}

div#etaRoutes,
div#etaTrips,
div#etaStops,
div#etaVehicles {
    margin-top: 5px;
}

div#addStopPopup .grid {
    padding-right: 10px;
}

div#addStopPopup .datetime-picker {
    margin-bottom: 15px;
    width: 100%;
}

div#addStopPopup .datetime-picker label {
    font-weight: 500;
}

div#addStopPopup .datetime-picker input {
    font-size: 12px;
}

div#addStopPopup .datetime-picker svg {
    font-size: 18px;
}

input#etaAssignedVehicle {
    font-size: 12px;
    top: 2px;
    position: relative;
}

input#etaTripRoute {
    font-size: 14px;
}