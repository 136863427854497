div#routesGrid {
  margin-top: 10px;
}

div#routesGrid table {
  width: 100%;
  min-width: 100% !important;
}

div#routesGrid table > thead {
  background-color: #f0f0f3;
}

div#routesGrid table thead > tr,
div#routesGrid table tbody > tr {
  height: auto;
}

div#routesGrid table tbody > tr > td {
  font-size: 12px;
  padding: 1px 4px;
}

.smallIcon {
  padding: 5px !important;
}

.smallIcon svg {
  font-size: 14px;
}

input#routeColor {
  background: #6b6ade;
  color: #fff !important;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  text-transform: uppercase;
}

div#routeColorPicker > div:nth-child(2) {
  margin-right: 0 !important;
}
div#routeColorPicker > div:nth-child(2) > div {
  right: 15px;
  top: 40px;
}

div#addRoute .dialogContent {
  min-height: 275px;
}
