div#reportTopBar {
  display: flex;
  background: white;
  box-shadow: 0px 2px 5px #3535493b;
  height: 50px;
  align-items: center;
  padding: 0px 15px;
  position: fixed;
  width: 100%;
  z-index: 9;
  justify-content: space-between;
}

.reports {
  height: 100%;
}

.report-leftbar {
  position: fixed;
  z-index: 99999999;
  top: 0;
}

.mapTabTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
}

.mapTabTitle small {
  font-size: 12px;
}

.reportSideBarWrapper {
  padding: 0px 8px;
  height: 100%;
  overflow-y: auto;
}

nav.menu > div {
  padding-left: 0;
}

.cpanelPageWrapper {
  margin-left: 60px;
  padding: 5px 15px;
  padding-top: 50px;
}

.cpanelPageWrapper .dataRGrid {
  padding: 0px 0px;
}

.charts {
  display: flex;
  flex-wrap: wrap;
}

.backdrop {
  z-index: 9999999999 !important;
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card {
  width: 18%;
  padding: 0px 2px;
  margin-bottom: 30px;
}

.reports-card > div {
  padding: 10px !important;
  display: flex;
  flex-direction: column;
}

.reportcard-header {
  display: flex;
  align-items: center;
  padding: 10px;
}

p.viewmore-link {
  color: var(--primaryColor);
  cursor: pointer;
  text-align: center;
  margin: 10px 0px;
  font-size: 14px;
  margin-bottom: 0;
}

.reportcard-icon {
  width: 30%;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 1px 2px 5px #c6c6c6;
}

.reportcard-icon.total {
  background: var(--primaryColor);
}

.reportPopupWrapper.all h2 {
  color: var(--primaryColor);
}

.reportcard-icon.running {
  background: green;
}

.reportcard-icon.idle {
  background: darkorange;
}

.reportcard-icon.stopped {
  background: red;
}

.reportcard-icon.nosignal {
  background: #c6c6c6;
}

.reportcard-icon.available {
  background: darkviolet;
}

.reportcard-icon.busy {
  background: red;
}

div#reportFooter {
  text-align: center;
  font-size: 12px;
  padding: 5px 0px;
  background: #ededed;
}

div#reportFooter p {
  margin: 4px 0px;
  margin-top: 6px;
}

.reportcard-icon svg {
  color: #fff;
}

.reportcard-details {
  width: 70%;
  padding: 0px 5px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.reportcard-title {
  margin-top: 10px;
  margin-bottom: 0;
  color: gray;
  font-size: 14px;
  font-weight: normal;
}

.reportcard-desc {
  font-size: 30px;
  margin-top: 8px;
  margin-bottom: 5px;
}

nav.breadcrumb {
  padding-left: 0px;
}

.whiteLoader svg {
  color: #fff;
}

.reportPopup-wrapper {
  display: flex;
  justify-content: center;
  min-height: 300px;
  align-items: center;
  flex-direction: column;
}

p.reportDescription {
  margin-bottom: 0;
  margin-top: -10px;
  font-size: 12px;
  color: #0000008a;
}

.reportFilters {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: center;
}

.width10 {
  width: 10%;
}

.width15 {
  width: 15%;
}

.width20 {
  width: 20%;
}

.width25 {
  width: 25%;
}

.width30 {
  width: 30%;
}

.width33 {
  width: 33%;
}

.width45 {
  width: 45%;
}

.width48 {
  width: 48%;
}

.width50 {
  width: 50%;
}

.width100 {
  width: 100%;
}

.height100 {
  height: 100%;
}

.padLeft-10 {
  padding-left: 10px;
}

.primaryBtn {
  background: #6b6bde !important;
  color: #fff !important;
  padding: 4px 15px !important;
  font-size: 12px !important;
  font-weight: bold !important;
}

.primaryBtn:hover {
  background: #6b6bde !important;
}

.cpanelPageWrapper .uidatepicker label {
  display: none;
}

.cpanelPageWrapper .uidatepicker {
  padding: 0px 15px;
  margin-bottom: 0;
}

.reportFilters .uidatepicker .MuiInput-root {
  margin-top: 0;
}

.dataTables_scrollHead th {
  font-size: 12px;
  color: #fff;
  text-align: left;
}

.dataTables_scrollBody {
  font-size: 12px;
}

.autoCompleteDropdown input {
  font-size: 12px;
  font-weight: 500;
  top: 3px;
  position: relative;
}

label.MuiInputLabel-root[data-shrink="false"] {
  font-size: 12px;
  font-weight: 500;
}

.autocomplete-box > div > div {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.autocompletePopper {
  border: 1px solid #c6c6c6;
  border-top: none;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

div#reportGrid_info {
  font-size: 12px;
}

.reportSideBarWrapper .MuiListItemText-root {
  padding-left: 0px;
}

.reportSideBarWrapper .menuicon {
  justify-content: center;
  min-width: 40px;
}

.subtitle {
  font-size: 16px;
  margin: 5px 0px;
  color: var(--primaryColor);
  font-weight: 500;
}

.reportSideBarWrapper > nav > div.MuiListSubheader-root {
  color: var(--primaryColor);
  font-size: 16px;
  font-weight: 500;
}

.report-leftbar .leftdrawer-menu {
  width: 300px;
  box-shadow: 2px 0px 50px #5f5f5f;
}

.menuicon svg {
  font-size: 20px;
  color: var(--primaryColor);
}

.cpanel-menu {
  padding: 4px 0px !important;
  margin-bottom: 15px !important;
}

.cpanel-menu.active span {
  font-weight: 500;
}

.cpanel-menu span {
  font-size: 14px;
  color: #000;
  padding-top: 2px;
}

.leftdrawer-menu {
  box-shadow: 0px 1px 10px #c4c4c4;
}

.dialogBox.reportPopupWrapper {
  width: 100%;
}

.reportPopup-wrapper > div {
  width: 100%;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.primaryLoader {
  color: #6b6bde !important;
}

table.dataTable.no-footer {
  margin-top: 0px !important;
  padding: 4px 0px;
}

.dataTables_scrollHead th:before,
.dataTables_scrollHead th:after {
  top: 0px;
}

table#reportGrid tbody td {
  border: 1px solid #a9a9a9;
  border-top: 0;
  border-right: 0;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
  margin-bottom: 5px;
  margin-right: 5px;
  font-size: 14px;
}

.reportPopupWrapper .dialogContent {
  padding-top: 0px !important;
}

.uidatepicker button.MuiButtonBase-root {
  padding: 8px;
}

.dt-buttons {
  margin-bottom: -20px;
}

div#topLeftBox {
  padding-left: 60px;
}

div#topRightBox {
  padding-right: 25px;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 15px;
  padding-top: 4px;
}

div#topRightBox > button {
  padding: 8px;
}

div#topRightBox > button svg {
  color: #404040;
  font-size: 18px;
}

div#reportUserInfo h4 {
  margin-bottom: 0;
  margin-top: -2px;
  font-size: 14px;
  color: #676767;
  font-weight: 500;
}

div#reportUserInfo p {
  margin-bottom: 0;
  margin-top: 0px;
  font-size: 12px;
  color: #a5a5a5;
  text-transform: capitalize;
}

div#reportLogo img {
  border-radius: 2px;
}

div#reportUserInfo {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
}

div#reportGrid_paginate ul {
  display: flex;
  list-style: none;
  margin-top: -15px;
}

.reportGridWrapper {
  overflow: auto;
}

div#reportGrid_paginate ul > li {
  padding: 0px 5px;
  font-size: 12px;
}

div#reportGrid_paginate ul > li a {
  text-decoration: none;
}

div#reportGrid_paginate ul > li.disabled a {
  color: #9d9d9d !important;
}

table#reportGrid.paginated {
  font-size: 12px;
}

table#reportGrid.paginated thead th {
  border: 1px solid #c0c0c0;
  border-right: none;
  background: #2c2c45bd;
  color: #fff;
}

table#reportGrid.paginated thead th:last-child {
  border-right: 1px solid #c0c0c0;
}

table#reportGrid tbody td:last-child {
  border-right: 1px solid #c0c0c0;
}

div#reportGrid_paginate ul > li.active {
  font-weight: 500;
}

table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after {
  top: 2px;
}

.dataTables_scrollHead {
  background: #464667;
}

div#reportGrid_wrapper thead th {
  color: #fff;
  border-right: 1px solid #6b6b7b;
}

.reportFilters .autocomplete-box input {
  font-size: 12px;
  top: 2px;
  position: relative;
}

/* CPANEL */
ul.gridActionBtns {
  display: flex;
  margin: 0;
  list-style: none;
  padding: 0;
  align-items: center;
  margin-top: 8px;
}

ul.gridActionBtns > li > button {
  padding: 8px !important;
  margin-left: 5px;
}

ul.gridActionBtns > li > button svg {
  font-size: 16px;
}

ul.gridActionBtns .SearchInput {
  margin-top: 0px !important;
  font-size: 12px !important;
  min-width: 200px;
}

div#cpanelMastersWrapper {
  margin-top: -5px;
}

.cpanelPageWrapper .dataRGrid > div > div > div {
  overflow: auto;
}

.autocomplete-box input {
  font-size: 12px;
  top: 2px;
  position: relative;
}

.labelBorder {
  border-bottom: 1px solid #dfdfdf;
  width: 98%;
  padding-bottom: 8px;
  margin-bottom: 15px;
}

#addCPanelUser .dialogBtn {
  display: block;
}

ul.popupActionBtns {
  margin-top: -5px;
}

ul.popupActionBtns > li:first-child {
  position: relative;
}

ul.popupActionBtns > li:first-child button {
  top: -2px !important;
}

div#userAddVehicleDialog .datetime-picker {
  width: 99%;
}

div#userAddVehicleDialog .datetime-picker input {
  font-size: 12px;
}

div#userAddVehicleDialog .datetime-picker svg {
  font-size: 18px;
}

div#cpanelUsers button.gridBtn {
  padding: 0px 6px;
  font-size: 12px;
  min-width: auto;
  color: var(--primaryColor);
}

.summaryWrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: row !important;
}

.summaryBox {
  background: linear-gradient(
    159deg,
    rgb(5, 176, 133) 0%,
    rgb(27, 212, 166) 59%
  );
  padding: 10px 30px;
  box-shadow: 1px 2px 5px #e5e5e5;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

.summaryBox.bgGreen {
  background: linear-gradient(
    159deg,
    rgb(5, 176, 133) 0%,
    rgb(27, 212, 166) 59%
  );
}

.summaryBox.bgRed {
  background: linear-gradient(
    150deg,
    rgb(255, 141, 141) 9%,
    rgb(212, 27, 27) 90%
  );
}

.summaryBox.bgBlue {
  background: linear-gradient(
    150deg,
    rgb(148, 223, 245) 9%,
    rgb(27, 65, 212) 90%
  );
}

.summaryBox.bgPurple {
  background: linear-gradient(
    150deg,
    rgb(205, 195, 255) 9%,
    rgb(106, 111, 230) 90%
  );
}

.summaryBoxHeader {
  display: flex;
  color: #fff;
  align-items: center;
}

.summaryBoxHeader p {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  margin-left: 10px;
}

.summaryBoxBody p {
  font-size: 38px;
  margin: 10px 0px;
  font-weight: 500;
  color: #fff;
  text-shadow: 1px 2px 5px #000;
}

/* CPanel */
.cpanelPageWrapper .mapTabIndicator {
  display: none;
}
div#cpanelMastersWrapper .mapTabs button {
  margin-right: 15px;
}

.dialogBody.zonePopupBody {
  min-height: auto !important;
}

div#cpanelVehicleDialog .dialogBody.zonePopupBody {
  min-width: 400px;
}

div#cpanelVehicleDialog .dialogContent {
  padding-right: 10px !important;
}

.cpanelPageWrapper .summaryWrapper {
  justify-content: space-between;
}
.cpanelPageWrapper .summaryBox {
  width: 15%;
  height: 100px;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 1px 1px 5px #b7b7b7;
}
.cpanelPageWrapper .summaryBox .summaryBoxHeader {
  width: 30px;
  height: 30px;
  justify-content: center;
  border-radius: 50%;
  background: #adadff1f;
  padding: 15px;
}
.cpanelPageWrapper .summaryBox .summaryBoxBody {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cpanelPageWrapper .summaryBox .summaryBoxBody h4 {
  font-size: 36px;
  margin: 10px 0px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0px;
}
.cpanelPageWrapper .summaryBox .summaryBoxBody p {
  font-size: 16px;
  text-shadow: none;
  color: #8a8a8a;
}
.cpanelPageWrapper .summaryBox svg {
  font-size: 26px;
}
.cpanelPageWrapper .summaryBox.bgBlue svg {
  color: #009fff;
}
.cpanelPageWrapper .summaryBox.bgGreen svg {
  color: #00b184;
}
.cpanelPageWrapper .summaryBox.bgRed svg {
  color: #aba6a6;
}
.cpanelPageWrapper .summaryBox.bgPurple svg {
  color: #6a6fe6;
}
.cpanelPageWrapper .summaryBox.bgOrange svg {
  color: #ff6b00;
}
