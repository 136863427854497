.poiTab {
    margin-top: 5px;
    padding: 0px 10px;
    border-bottom: 1px solid #EAEBF2;
    min-height: 34px !important;
}

button.tab {
    min-height: auto !important;
    text-transform: initial;
    font-size: 12px;
    min-width: auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.poiTabIndicator {
    background-color: #6b6bde !important;
}

.poiNavTabButtons {
    display: none !important;
}

.poiScrollBar {
    overflow-x: hidden !important;
}

.poiTab .poiTabLabel {
    padding: 10px 15px;
}