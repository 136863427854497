.pointsTab {
    background-color: #fff;
    margin: 10px 0px;
}

.pointsTab ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.pointsTab ul>li:first-child {
    width: 50%;
    margin-right: 10px;
    position: relative;
}

.pointsTab ul>li.poiBtn svg {
    font-size: 16px;
}

.pointsTab ul>li.poiBtn>button {
    padding: 6px;
}

.pointsTab ul>li .SearchInput {
    margin-top: 3px !important;
    height: 14px !important;
    font-size: 12px !important;
}

.pointsTab .iconButton.searchBtn {
    padding: 4px;
    top: 0;
    right: -2px;
}

.pointsTab li.poiBtn {
    margin-right: 5px;
}

.smallIcon {
    padding: 5px !important;
}

.smallIcon svg {
    font-size: 14px;
}

.pointsTab ul>li .SearchInput {
    margin-top: 3px !important;
    font-size: 12px !important;
    min-height: 15px !important;
}

.pointsTab .iconButton.searchBtn {
    padding: 4px;
    top: 0;
    right: -2px;
}