.trackVehicle {
  margin: 10px 0px;
  height: 100%;
}

p.label {
  color: #6b6ade;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 5px;
}

.chip {
  margin-bottom: 10px !important;
  margin-right: 10px !important;
  background-color: #fff !important;
  border: 1px solid #dfdbdb !important;
  height: auto !important;
  padding: 4px 0px !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  font-weight: 500;
  color: #434343 !important;
}

.chip.active {
  background: #6b6ade !important;
  color: #fff !important;
  border: 1px solid #6b6ade !important;
}

.chip:active,
.chip:hover {
  background: #6b6ade !important;
  color: #fff !important;
  border: 1px solid #6b6ade !important;
}

.trackBtn {
  position: relative;
  top: -1px;
  left: 8px;
}

.trackBtn > button {
  font-size: 12px;
  border: 1px solid var(--primaryColor);
  padding: 2px 5px;
  margin-right: 10px;
  text-transform: initial;
  background-color: #fff;
  color: var(--primaryColor);
}

.trackBtn > button.active {
  border-color: #9f9fa2;
  color: #9f9fa2;
  pointer-events: none;
}

.trackBtn > button:hover {
  background-color: #fff;
  border-color: var(--primaryColor);
  color: var(--primaryColor);
}

#stopsDiv .selectDropdown {
  width: 100%;
  min-width: auto !important;
  padding-right: 30px !important;
}

ul[role="listbox"] {
  padding: 0;
}

ul[role="listbox"] > li {
  padding: 2px 5px;
  font-size: 12px;
}

p.label.stopsLabel {
  margin-bottom: -8px;
  margin-top: 5px !important;
}

#trackDateFilter {
  border: 1px solid whitesmoke;
  padding: 0px 5px;
  margin-bottom: 15px;
  font-size: 12px;
}

input#trackVehicles {
  font-size: 12px;
  font-weight: 500;
  top: 3px;
  position: relative;
}

label#trackVehicles-label[data-shrink="false"] {
  font-size: 12px;
  font-weight: 500;
}

#stopsDiv {
  display: flex;
  align-items: flex-end;
}

div#trackSummaryBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: -4px;
  background: var(--primaryColor);
  padding: 8px 5px;
  border-radius: 4px;
}

div#trackSummaryBox > div {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

div#trackSummaryBox > div > svg {
  font-size: 16px;
  color: #ffffff;
}

div#trackSummaryBox > div > h4 {
  font-size: 12px;
  margin: 0px;
  color: #fff;
  cursor: default;
  padding-left: 2px;
}
