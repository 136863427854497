.leftFixedMenu.hide {
    display: none;
}

input#showpoint {
    border: 1px solid whitesmoke;
    padding: 5px 5px;
    font-size: 12px;
    border-radius: 5px;
    margin: 10px 0px;
}

div#leftbarHandler {
    position: absolute;
    top: 8px;
    right: 4px;
}