.downloadApp {
    height: 100%;
    text-align: center;
    padding: 15px;
    padding-top: 10%;
}

.downloadApp>ul>li {
    display: flex;
    width: 50%;
    padding: 5px;
}

.downloadApp>ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
}

.downloadApp>ul>li img {
    width: 100%;
}

.downloadApp h4 {
    color: #000;
    font-size: 24px;
    text-shadow: 1px 2px 5px #fff;
}