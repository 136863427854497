div#trackTimeline {
  height: 49.5%;
  overflow-y: auto;
  margin-top: 12px;
}

.trackTimeline .stepper {
  padding: 0px;
  padding-left: 0px;
  position: relative;
}

.trackTimeline .stepper svg {
  font-size: 12px;
}

.trackTimeline .stepper svg > text {
  display: none;
}

.step:last-child::after {
  position: absolute;
  bottom: -14px;
  left: 0;
  right: 0;
  content: "";
  width: calc(100% - 18px);
  height: 1px;
  display: block;
  margin: 0 auto;
  border-bottom: 1px dotted #a5a5a5;
}

.trackTimeline .stepContent {
  font-size: 12px;
  padding-left: 14px;
  margin-left: 5px;
  margin-top: 0px;
  color: #959595;
}

span.stepLabel {
  font-size: 12px;
}

.trackTimeline .stepConnector {
  margin-left: 11px;
  padding-bottom: 0px;
  margin-top: 0px;
}

.trackTimeline .stepConnector > span {
  min-height: 0px;
}

.trackTimeline .step svg circle {
  color: #9f9fa2;
}

.trackTimeline .step.zonein svg circle {
  color: green;
}

.trackTimeline .step.running svg circle {
  color: green;
}

.trackTimeline .step.breakdown svg circle {
  color: red;
}

.trackTimeline .step.idle svg circle {
  color: red;
}

.trackTimeline .step.zoneout svg circle {
  color: #ff9800;
}

.step.breakdown .stepLabel {
  color: red;
}

p.stepStatus {
  display: flex;
  align-items: center;
  margin: 5px 0px;
}

p.stepStatus > span.statusLabel {
  padding: 2px 10px;
  border-radius: 25px;
  color: #fff;
  margin-right: 10px;
}

p.stepStatus.running > span {
  background: green;
}

p.stepStatus.stopped > span {
  background: red;
}

p.stepStatus.running > span.statusTime {
  color: green;
  background: none;
}

p.stepStatus.stopped > span.statusTime {
  color: red;
  background: none;
}

p.stepDate {
  margin: 0;
  color: #6e6e6e;
  margin-top: 2px;
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 12px;
}

p.stepSummary {
  margin-bottom: 0px;
  margin-top: 0px;
  color: #000;
  text-align: justify;
  line-height: 1.5;
}

.stepRunningSummary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -10px;
  margin-bottom: -15px;
}

.stepRunningSummary ul {
  list-style: none;
  padding-left: 0;
  color: #000;
}

.stepRunningSummary ul > li:first-child {
  margin-bottom: 4px;
  font-weight: 500;
}

.stepRunningSummary > div:nth-child(2) {
  text-align: center;
}

.stepRunningSummary > div:last-child {
  text-align: right;
}

div#trackTimeline .step {
  cursor: pointer;
  padding-left: 6px;
  padding-bottom: 8px;
  margin-right: 2px;
}

div#trackTimeline .step:hover {
  background-color: #eeedff;
  border-radius: 4px;
}
