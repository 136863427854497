div#alertList .trackTimeline.scrollBar {
  height: 80%;
  overflow-y: auto;
  border-top: 1px solid #e9e9e9;
  padding-top: 5px;
}

div#alertList {
  height: 100%;
  overflow-y: hidden;
}

div#alertsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  padding-top: 0px;
  margin-bottom: 4px;
  flex-direction: column;
}

div#alertLeftBar h4 {
  font-size: 14px;
  font-weight: 500;
}

div#alertRightBar > div {
  margin-top: -3px;
}

.stepWrapper {
  cursor: pointer;
}

div#alertList p.stepStatus {
  margin-bottom: 0px;
  margin-top: 2px;
  color: var(--secondaryColor);
}

div#alertList p.stepDate {
  color: #9c9c9c;
  margin-bottom: 8px;
  font-size: 11px;
  border-bottom: 1px solid #efefef;
  padding-bottom: 8px;
}

div#alertList p.stepSummary.vehicleNum {
  color: var(--primaryColor);
  font-size: 12px;
  font-weight: bold;
}

div#alertList svg.stepIcon {
  color: var(--primaryColor);
  font-size: 16px !important;
  left: -2px;
  position: relative;
}

div#alertList span.MuiStepLabel-iconContainer {
  padding-right: 4px;
}

div#alertList .stepWrapper:hover p.stepStatus {
  color: #000 !important;
}

div#alertList .stepWrapper:hover p.stepSummary.latlng,
div#alertList .stepWrapper:hover p.stepDate {
  color: #000 !important;
}

div#alertList .stepWrapper.active p.stepStatus {
  color: #000 !important;
}

div#alertList .stepWrapper.active p.stepSummary.latlng,
div#alertList .stepWrapper.active p.stepDate {
  color: #000 !important;
}

div#alertList #noAlerts {
  text-align: center;
  font-size: 12px;
}

div#alertList #loadMoreAlert {
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  color: var(--primaryColor);
}

div#alertLeftBar {
  width: 48%;
}

div#alertLeftBar .datetime-picker {
  width: 100%;
}

div#alertLeftBar > div > div {
  width: 95%;
}

div#alertLeftBar input {
  font-size: 12px;
  font-weight: 500;
}

div#alertLeftBar button {
  padding: 6px;
}

div#alertLeftBar svg {
  font-size: 18px;
}

div#alertRightBar > .dropdown {
  width: 160px;
}

div#alertRightBar {
  display: flex;
  align-items: center;
  position: relative;
}

div#alertRightBar > button {
  padding: 5px;
  position: relative;
  right: -5px;
}

div#alertRightBar > button svg {
  font-size: 18px;
}

div#alertTopBar {
  margin-bottom: 0px;
  width: 100%;
}

div#alertBottomBar {
  display: flex;
  margin-top: -2px;
}

#totalAlertRecords {
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  padding-right: 5px;
  color: var(--secondaryColor);
}
