.topMapBar {
  position: fixed;
  width: 100%;
  transition: width 0.3s ease;
  background-color: #fff !important;
  z-index: 999;
  box-shadow: 0px 0px 15px #0000004d;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.topMapBar > .topLeftMenu {
  /* transform: translateX(60px); */
  margin-left: 60px;
  align-items: center;
  display: flex;
}

.topMapBar.open > .topLeftMenu {
  margin-left: 0;
}

.mapPage .topMapBar.open > .topLeftMenu.leftbar {
  transform: translateX(0px);
}

.mapPage .topMapBar > .topLeftMenu.leftbar {
  transform: translateX(448px);
  margin-left: 0;
}

.topLeftMenu,
.topRightMenu {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.topMapBar > .topRightMenu {
  display: flex;
  align-items: center;
}

.topLeftMenu li,
.topRightMenu li {
  display: inline;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.topicon-pad {
  padding: 6px !important;
}

.topMapBar.hide {
  display: none;
}

.avatar-wrapper {
  display: inline-block;
}

.avatar-wrapper img {
  width: 25px;
}

.avatar-wrapper h4 {
  font-size: 12px;
  text-transform: capitalize;
  margin: 0;
}

.leaflet-control-easyPrint {
  display: none;
}

img#myAccountProfile {
  margin: 0 auto;
  display: block;
  width: 100px;
  border: 1px solid whitesmoke;
  margin-bottom: 10px;
}

.settingsTabs button {
  color: #000 !important;
  font-size: 12px;
  min-height: auto;
  min-width: 130px;
  background: none;
}

.settingsTabs button.activeTab {
  border-bottom: 2px solid var(--primaryColor);
  background: none;
  color: #000 !important;
}

.settingsTabIndicator {
  display: none;
}

button#etaMasterIcon svg {
  font-size: 18px;
  color: #000;
}
