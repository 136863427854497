.fleetGrid {
    margin-top: 10px;
    border: 1px solid #d8d8d8;
}

.fleetGrid thead>tr,
.fleetGrid tbody>tr {
    height: auto;
}

.fleetGrid thead>tr>th {
    padding: 0px 5px !important;
    background-color: #ececec;
    font-size: 12px;
}

.fleetGrid tbody>tr>td {
    padding: 1px 5px !important;
    font-size: 12px;
}