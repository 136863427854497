.settingsDialogBox.dialogBox {
  left: 30% !important;
}
div#addSubAccount {
  width: 100%;
  left: 0px !important;
}
.settingsDialogBox .formLabel,
div#addSubAccount .datetime-picker > label {
  font-size: 12px;
  font-weight: 500;
  color: var(--secondaryColor);
}
.settingsDialogBox .txtfield.accessUrl > label {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondaryColor);
}
div#addVehicle .datetime-picker {
  width: 100%;
}
div#addVehicle .datetime-picker input {
  font-size: 12px;
}
