div#bottomBar {
  position: fixed;
  width: 100%;
  z-index: 999;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  right: 65px;
}

div#bottomBar button {
  background: #fff;
  left: 5px;
  margin-left: 15px;
  min-width: 100px;
  bottom: 10px;
  font-size: 12px !important;
  padding: 2px;
  padding-left: 6px;
  text-align: left;
  justify-content: flex-start;
  box-shadow: 0px 1px 5px #00000042;
}

.mapTypeItem > div {
  min-width: auto;
  margin: 0px !important;
  position: relative;
  top: 2px;
}

.mapTypeLabel > span {
  padding-left: 5px;
  position: relative;
  top: -1px;
  font-size: 12px;
}

span.showVehiclesIcon {
  position: relative;
  right: -2px;
  top: 3px;
}

span.mapTypeIcon {
  position: relative;
  right: -10px;
  top: 4px;
}

div#bottomBar button svg {
  height: 20px;
}

div#showVehiclesList,
div#showVehicleStatusList,
div#mapTypeList {
  position: relative;
  bottom: 10px;
}

div#showVehiclesList:after,
div#showVehicleStatusList:after,
div#mapTypeList:after {
  width: 0px;
  content: "";
  position: absolute;
  left: 60px;
  border-top: 7px solid white;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.showVehiclesList {
  padding: 0px !important;
  min-width: 150px !important;
}

.showVehiclesList > li > div {
  padding: 0px 5px !important;
  padding-left: 8px !important;
}

.showVehiclesList .showVehiclesItemAction {
  right: 0 !important;
  padding-right: 3px !important;
}

.mapTypeList {
  padding: 0 !important;
  min-width: 140px !important;
}

.showVehiclesItem {
  padding: 4px 6px !important;
}

.mapTypeItem {
  padding: 4px 6px !important;
}

.showVehiclesItemAction svg {
  font-size: 16px !important;
}

.mapTypeItemAction svg {
  font-size: 16px !important;
}

div#showVehiclesList:after {
  width: 0px;
  content: "";
  position: absolute;
  left: 60px;
  border-top: 7px solid white;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

div#mapTypeList:after {
  width: 0px;
  content: "";
  position: absolute;
  left: 70px;
  border-top: 7px solid white;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.showVehiclesItemAction > span {
  color: var(--primaryColor) !important;
  padding: 5px;
}

.mapTypeItemAction > span {
  color: var(--primaryColor) !important;
  padding: 5px;
}

.mapTypeItem > img {
  width: 15px;
  height: 15px;
}

.mapTypeLabel {
  padding-left: 2px !important;
  padding-right: 25px !important;
}

.mapTypeItemAction {
  right: 0 !important;
}

.showVehiclesItemAction {
  right: 0 !important;
}

.showVehiclesItemText span {
  font-size: 12px;
}

.bottomdrawer-menu > div {
  padding: 5px 65px;
  padding-right: 5px;
  z-index: 9999 !important;
}

.bottomdrawer-menu {
  z-index: 9999 !important;
}

.bottomdrawer {
  position: static !important;
}

.trackBottomBarTab {
  min-height: auto !important;
}

div#trackbarCloseBtn {
  position: absolute;
  z-index: 999;
  right: 3px;
  right: 4px;
  top: 3px;
}

div#trackbarCloseBtn button {
  padding: 2px;
}

div#trackbarCloseBtn button svg {
  font-size: 18px;
}

div#trackGraphWrapper .dropdown {
  width: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
}

div#trackMessagesGrid thead th > div:last-child {
  right: 0;
}

div#trackMessagesGrid thead th svg {
  margin-left: 0;
}

div#liveVehiclesInfoGrid > ul {
  display: flex;
  padding: 0;
  margin-top: -30px;
  position: relative;
  list-style: none;
  justify-content: space-between;
}

div#liveVehiclesInfoGrid > ul > li svg {
  font-size: 18px;
}

div#liveVehiclesInfoGrid > ul > li:first-child {
  width: 250px;
  position: relative;
  top: 8px;
}

div#liveVehiclesInfoGrid > ul .searchBtn {
  right: 0;
}

div#bottomBar button#showPopupVehicles {
  min-width: 150px;
  padding-left: 0;
  display: flex;
  justify-content: center;
}

.trackBottomBarTab div[role="tablist"] {
  display: block;
  margin-top: 5px;
}

.trackBottomBarTab div[role="tablist"] > button {
  border-radius: 15px;
  min-width: 100px;
}

.trackBottomBarTab div[role="tablist"] > button.activeTab {
  background: var(--primaryColor);
}

.trackBottomBarTab .editVehicleTabIndicator {
  display: none;
}

div#trackBottomContainer {
  position: relative;
}

div#trackPlaybackControls {
  position: absolute;
  top: 5px;
  right: 6%;
  width: 25%;
  z-index: 999999;
  display: flex;
  justify-content: flex-end;
}
div#trackPlaybackControls > button {
  border-radius: 4px;
  padding: 3px 6px;
  margin: 3px 0px;
}
div#trackPlaybackControls > button svg {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}
div#trackPlaybackControls > div {
  font-size: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 500;
  width: 30px;
  color: var(--primaryColor);
}
div#trackPlaybackControls > button.disabled svg {
  color: #b5b5b5;
}
div#trackPlaybackControls .green,
div#trackPlaybackControls .green svg {
  color: var(--primaryColor);
}
div#trackPlaybackControls > svg {
  color: #b5b5b5;
  font-size: 18px;
  font-weight: bold;
  padding: 3px 6px;
  margin: 3px 0px;
}

div#trackGraphFilter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
div#trackGraphFilter > div {
  width: 25% !important;
}
div#trackGraphFilter > span {
  width: 70%;
  position: relative;
  right: -20px;
}

.trackslidebar {
  color: #3a8589;
  height: 3px;
  padding: 13px 0px;
}
.trackslidebarThumb {
  height: 27px;
  width: 27px;
  background-color: #fff;
  border: 1px solid #fff;
  margin-top: -12px;
  margin-left: -13px;
  box-shadow: #ebebeb 0 2px 2px;
}
.trackslidebarTrack {
  height: 3px;
}
.trackslidebarRail {
  color: #9e9e9e;
  opacity: 1;
  height: 3px;
}
.trackslidebarTrack {
  height: 25px !important;
  top: 2px;
}
.trackslidebarRail {
  height: 25px !important;
  top: 2px;
  width: 99% !important;
}
.trackslidebarThumb {
  width: 5px !important;
  box-shadow: 0px 0px 4px #000;
  border-radius: 0 !important;
  background: red;
  top: 2px;
  height: 35px !important;
  border: 1px solid #fff;
}
.trackInfo {
  position: absolute;
  right: 24%;
  font-size: 12px;
  font-weight: 500;
  top: 2px;
  color: var(--secondaryColor);
  z-index: 9999999;
}

ul.graphInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  list-style: none;
  margin: 0;
  margin-top: 10px;
}
ul.graphInfo > li {
  padding-right: 15px;
  width: 20%;
  position: relative;
  font-size: 12px;
}
ul.graphInfo > li > span {
  background: red;
  width: 10px;
  height: 10px;
  position: absolute;
  left: -15px;
  top: 2px;
  border-radius: 50%;
}
ul.graphInfo > li#harshAcceration > span {
  background: #000;
}
ul.graphInfo > li#harshBraking > span {
  background: red;
}
ul.graphInfo > li#harshCornering > span {
  background: #ffaa00;
}
