.expansionPanel {
    padding: 0px;
    box-shadow: none !important;
    overflow: hidden;
}

.expansionPanelSummary {
    min-height: auto !important;
    padding: 0px !important;
}

.expansionPanelSummary>div {
    margin: 0 !important;
}

.expansionPanelDetails {
    padding: 0px 0px !important;
    position: relative;
    border-radius: 0;
    font-size: 12px;
    display: block !important;
}

.expansionPanelDetails p {
    margin: 0px;
}

span.expansionPanelTitle {
    width: 100%;
    left: 0px;
    position: relative;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    color: #434343;
}

.expansionPanel.open .toggleIcon>.expand {
    display: none;
}

.expansionPanel.open .toggleIcon>.minimize {
    display: block;
}

.expansionPanel .toggleIcon>.minimize {
    display: none;
}

.expansionPanel .toggleIcon>.expand {
    display: block;
}

span.visibilityIcon.hide {
    color: #000;
    opacity: 0.2;
}

span.visibilityIcon {
    opacity: 1;
    width: 30px;
    position: absolute;
    left: 0;
    text-align: center;
}

span.visibilityIcon svg,
.toggleIcon>.expand>svg,
.toggleIcon>.minimize>svg {
    width: 15px;
    height: 15px;
    color: #434343;
    font-size: 14px;

}

.expansionPanelSummary .toggleIcon {
    padding: 0px;
    width: 30px;
    position: absolute;
    right: 0;
    text-align: center;
    padding-right: 0 !important;
}