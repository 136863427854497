div#root.login {
    background-image: url("../../images/login/loginbg.jpeg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
}

#logintopbar {
    width: 100%;
    background-color: #ffffffa6;
    padding: 15px 30px;
    display: none;
}

#logintopbar a {
    float: right;
    color: #6b6bde;
    padding-top: 15px;
}

ul#rememberPassword svg {
    color: #6b6bde;
}

button#loginFormBtn {
    background-color: #6b6bde;
}

#loginbody {
    position: relative;
    height: calc(100% - 25px);
    display: flex;
}

#loginfooter {
    background-color: #ffffffa6;
    position: fixed;
    bottom: 0;
    width: 100%;
}

#loginlefttext {
    text-align: justify;
    width: calc(100% - 60%);
    padding: 15px;
    font-size: 22px;
    line-height: 1.5;
    letter-spacing: 1px;
    margin: auto auto;
}

div#loginlefttext p {
    display: none;
}

#loginfooter p {
    font-size: 12px;
    margin-bottom: 4px;
    text-align: center;
    margin-top: 8px;
}

#loginfooter ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    font-size: 12px;
}

#loginfooter ul>li {
    padding-right: 5px;
}

div#loginForm {
    background-color: #fffffff5;
    padding: 25px 15px;
    box-shadow: 0px 3px 15px #00000047;
    width: 350px;
    height: auto;
    display: table;
    margin: auto;
    border-radius: 10px;
}

p.loginFormTitle {
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 35px;
}

button#loginFormBtn {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    color: #fff
}

p#loginFormFooter {
    margin-bottom: 0;
    text-align: center;
}

ul#rememberPassword {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 0;
}

ul#rememberPassword li:last-child {
    padding-top: 14px;
}

.rememberMeCheckbox {
    color: #636363 !important;
}

input#loginUsername::after {
    border-color: #6B6BDE;
}

.iconColor {
    color: #313131;
}

input#loginUsername,
input#loginPassword {
    font-size: 16px;
}